import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { StyledHasuraValues } from "./StyledCareersPage";
import { Icon } from "../../globals/icons";

const hasuraValuesArr = [
  {
    title: "User First",
    desc: "We obsess about our users and their business so we can craft solutions that exceed expectations and inspire delight.",
    imgUrl: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1686955342/website%20v3/UserFirst_nil823.svg",
  },
  {
    title: "One Team",
    desc: "We cross all barriers to work together towards our common goals, maintaining individual accountability.",
    imgUrl: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1686955402/website%20v3/OneTeam_bxtmn3.svg",
  },
  {
    title: "Win or Learn",
    desc: "We move fiercely fast to problem-solve without fear of failure, to make improvements on a concrete hypothesis (bet) with every action.",
    imgUrl: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1686955317/website%20v3/WinLearn_afxspg.svg",
  },
];

const emojisArr = [
  {
    emoji: "🖖🏽",
  },
  {
    emoji: "✌🏽",
  },
  {
    emoji: "🤙🏽",
  },
  {
    emoji: "👊🏽",
  },
  {
    emoji: "👋🏽",
  },
  {
    emoji: "👍🏽",
  },
  {
    emoji: "🤏🏽",
  },
];

const slideOneSettings = {
  dots: false,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
};

export const HasuraValues = () => (
  <StyledHasuraValues className="layout-spacer">
    <section className="containerWrapper">
      <div className="heading-div">
        <h2>
          Help developers &amp; teams build the future they imagine&nbsp;
          <Slider className="sliderCarousel" {...slideOneSettings}>
            {emojisArr.map(({ emoji }, index) => (
              <span className="emoji-heading" key={index}>
                <span className="emoji" role="img" aria-label="emoji">
                  {emoji}
                </span>
              </span>
            ))}
          </Slider>
        </h2>
      </div>
      <div className="inspire-div">
        <p>
          At Hasura, we’re a globally distributed team solving some of the hardest problems in data
          access. As the amount of data in the world explodes and as the need for building products
          and applications becomes more urgent, we believe that making data available to developers
          in a way they love (GraphQL{" "}
          <span className="emoji" role="img" aria-label="emoji">
            ❤️
          </span>
          ) will address the most fundamental bottlenecks in making them productive.
        </p>
        <p>
          At Hasura we’re building tools and technology, based on a strong open-source foundation
          and ethos, with a focus on building a sustainable project, community and business around
          Hasura. Apart from maintaining our flagship open-source graphql-engine, we contribute to
          and financially support various open-source projects and foundations.
        </p>
        <p>
          Imagine a world where developers can choose their favorite databases and services (or
          activate legacy sources they hate working with!). Where much-needed security, performance
          and compliance policies just work, and don’t slow down the path to go from idea to
          reality.
        </p>
        <p>
          And of course, we make all this happen with great people. We’re a team of mission-driven
          individuals, and we’re continuously working towards creating a productive and high-impact
          environment for ourselves!
        </p>
      </div>
      <h3>Our Values</h3>
      <div className="values-wrapper-div">
        {hasuraValuesArr.map(({ title, desc, imgUrl }) => (
          <div key={title} className="value-top-wrapper">
            {/* <Icon variant={iconVariant} mr="14px" size="lg" minWidth="40px" mb="-3px" /> */}
            <img className="values-img" src={imgUrl} alt={title}></img>
            <div className="values-box">
              <p className="values-title">{title}</p>
              <p className="values-desc">{desc}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  </StyledHasuraValues>
);
