import React from "react";
import { css } from "styled-components";

import { Box, Heading, Flex, Image } from "../../../globals/UIKit";

export const FormHeading = ({
  toggleJobFormModal,
  isApplicationSuccessfulySubmitted,
  toggleApplicationSubmissionStatus,
}) => {
  const handleCloseButton = () => {
    toggleJobFormModal(false);

    if (isApplicationSuccessfulySubmitted) {
      toggleApplicationSubmissionStatus(false);
    }
  };

  return (
    <Flex justifyContent="space-between">
      <Image
        src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1679386576/main-web/icons/close_ectuo8.svg"
        alt="close-icon"
        minWidth="24px"
        maxWidth="24px"
        className="close-btn"
        role="button"
        css={css`
          cursor: pointer;
        `}
        onClick={handleCloseButton}
      />
      <Heading fontSize="24px" fontWeight={600} color="#000">
        Submit Your Application
      </Heading>
      {/* Dummy Element for managing space */}
      <Box as="span" width="24px" />
    </Flex>
  );
};
