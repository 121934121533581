const baseDomain =
  process.env.GATSBY_DEPLOY_ENV === "production" ? "hasura.io" : "hasura-stg.hasura-app.io";

const dbUrl = `https://data.${baseDomain}/v1/query`;

//const websiteApiUrl = `https://website-api.${baseDomain}`;
const websiteApiUrl = `https://website-api.hasura.io`;

export const FRESHTEAM_API_URL = `https://website-api.hasura.io`;
// export const FRESHTEAM_API_URL = "http://localhost:3000";

const hasuraConRegisterUrl = `${websiteApiUrl}/hasura-con/register`;

const hasuraConVerifyUrl = `${websiteApiUrl}/hasura-con/verify-registration`;

const hasuraCon21RegisterUrl = `${websiteApiUrl}/hasuracon-2021/register`;

const hasuraCon22UploadUrl = `${websiteApiUrl}/hasuracon-2022/upload`;

const hasuraCon21MarketingRegisterUrl = `${websiteApiUrl}/hasuracon-2021/ad-conversions`;

const gqlApiUrl = `https://graphql-engine-website.${baseDomain}/v1/graphql`;

const cloudDomain =
  process.env.GATSBY_DEPLOY_ENV === "production"
    ? "https://cloud.hasura.io"
    : "https://cloud.staging-2.hasura-app.io";

export {
  dbUrl,
  baseDomain,
  hasuraConRegisterUrl,
  hasuraConVerifyUrl,
  hasuraCon21RegisterUrl,
  hasuraCon21MarketingRegisterUrl,
  websiteApiUrl,
  gqlApiUrl,
  cloudDomain,
  hasuraCon22UploadUrl,
};
