import React from "react";

import { StyledHasuraPerks } from "./StyledCareersPage";
import { Icon } from "../../globals/icons";

const hasuraPerksArr = [
  {
    perkName: "Remote & Hybrid Work Environment",
    perkDesc: `Our team is spread across multiple countries, allowing for remote work or in-person collaboration at our office spaces in San Francisco and Bangalore. We believe in a flexible work model that considers individual preferences and promotes effective teamwork.`,
    iconVariant: "remote",
  },
  {
    perkName: "Self-Care Fridays",
    perkDesc: `We offer the second Friday of every month as a day off. This time allows our team members to engage in activities that rejuvenate and recharge them personally.`,
    iconVariant: "friday",
  },
  {
    perkName: "Equipment and Learning Allowance",
    perkDesc: `We have equipment and learning budgets to ensure that our employees have the necessary tools and resources to succeed in their roles. We invest in their growth and development by facilitating access to relevant learning opportunities.`,
    iconVariant: "equipment",
  },
  {
    perkName: "Donation Matching",
    perkDesc: `We have an annual donation matching fund to encourage and enhance team member donations to global organizations working towards equality & equity.`,
    iconVariant: "ownership",
  },
  {
    perkName: "Flexible Work Schedules & PTO",
    perkDesc: `Because of our asynchronous ways of working, our team members have the freedom and flexibility to set their work schedules ensuring adequate support and team coverage. This flexibility enables a healthy work-life balance, complemented by generous paid time off (PTO) options.`,
    iconVariant: "pto",
  },
  // {
  //   perkName: "Yearly offsites",
  //   perkDesc: `Regular off-sites to meet your team members and colleagues for fun and work - with at-least one annual full company gathering!`,
  //   iconVariant: "beach",
  // },
];

export const HasuraPerks = () => (
  <StyledHasuraPerks className="containerWrapper layout-spacer">
    <h3>Benefits</h3>
    <div className="perks-wrapper-div">
      {hasuraPerksArr.map(({ perkName, perkDesc, iconVariant }) => (
        <div className="perks-div" key={perkName}>
          <Icon variant={iconVariant} size="xxl" mb="11px" />
          <p className="perk-name">{perkName}</p>
          <p className="perk-desc">{perkDesc}</p>
        </div>
      ))}
    </div>
  </StyledHasuraPerks>
);
