import React from "react";

import { StyledLandingBanner } from "./StyledCareersPage";
import { FRESHDESK_JOBS_SECTION_LINK } from "../../utils/constants";

export const CareersLanding = () => {
  return (
    <StyledLandingBanner>
      <div className="landing-content-wrapper">
        <h1>Help developers build great products</h1>
        <p>Join our globally distributed team and work on the future of data-access and GraphQL</p>
        <a href={FRESHDESK_JOBS_SECTION_LINK}>
          <button className="hasura-btn hasura-btn-md hasura-blue-btn">See Open Roles</button>
        </a>
      </div>
    </StyledLandingBanner>
  );
};
