import React, { useState, useEffect } from "react";
import { css } from "styled-components";

import { StyledCareersPage } from "./StyledCareersPage";
import { StyledSubTitle2, StyledDesc2 } from "../shared/StyledTypography";
import { JobsList } from "./JobsList";
import { Box, Flex, Text, Image } from "../../globals/UIKit";
import { scrollToViewPosition } from "../../utils/helpers";
import { navigate } from "gatsby";

export const FreshDeskJobs = ({ jobsDataArr, location }) => {
  const [isRemoteJobsFilterActive, updateRemoteJobsFilter] = useState(false);

  const toggleRemoteJobsFilter = () => updateRemoteJobsFilter(!isRemoteJobsFilterActive);

  const [isJobData, setIsJobData] = useState(jobsDataArr);

  const [allDepartmentsArr, updateAllDepartmentsArr] = useState([]);

  const searchParams = new URLSearchParams(location?.search);

  const getSearchDepartmentName = searchParams => {
    const departmentSearchId = searchParams.get("departmentName");

    if (departmentSearchId === "G" && location.search === "?departmentName=G&A") {
      return "G&A";
    }

    return departmentSearchId;
  };

  const searchDepartmentName = getSearchDepartmentName(searchParams);

  // Get deafult selected department filter ****** //

  const getDepartmentName = () => {
    return searchDepartmentName || "All Departments";
  };

  // **************************** //

  useEffect(() => {
    if (searchDepartmentName) {
      scrollToViewPosition("hasura-open-roles");
    }
  }, [location?.search]);

  // **************************** //

  const [departmentData, setDepartmentData] = useState(getDepartmentName());

  const [locationData, setLocationData] = useState("All Locations");

  useEffect(() => {
    if (isRemoteJobsFilterActive) {
      setLocationData("All Locations");

      const fullyRemoteJobsData =
        isJobData?.constructor.name === "Array" && isJobData.filter(jobObj => jobObj.remote);

      return setIsJobData(fullyRemoteJobsData);
    }

    return setIsJobData(jobsDataArr);
  }, [isRemoteJobsFilterActive]);

  // Filter data for departments and locations
  useEffect(() => {
    const uniqueDept = [];

    for (const item of jobsDataArr) {
      const isDuplicate = uniqueDept.find(obj => obj.department.name === item.department.name);

      if (!isDuplicate && !item.deleted) {
        uniqueDept.push(item);
      }
    }

    updateAllDepartmentsArr(uniqueDept);
  }, []);

  const uniqueLocations = [];

  if (isJobData) {
    for (const item of isJobData) {
      const isDuplicate = uniqueLocations.find(obj => obj.branch.city === item.branch.city);

      if (!isDuplicate && !item.deleted) {
        uniqueLocations.push(item);
      }
    }
  }

  const handleDepartmentSelect = e => {
    if (e) {
      if (searchDepartmentName) {
        if (e.target.value === "All Departments") {
          navigate(`/careers`);
        } else {
          navigate(`/careers/?departmentName=${e.target.value}`);
        }
      }

      setDepartmentData(e.target.value);
    }
  };

  const handleLocationSelect = e => {
    if (e) {
      setLocationData(e.target.value);
    }
  };

  let selectedDepartmentDataFilter;

  if (isJobData) {
    if (locationData === "All Locations" && departmentData !== "All Departments") {
      selectedDepartmentDataFilter = isJobData.filter(t => t.department.name === departmentData);
    } else if (departmentData === "All Departments" && locationData !== "All Locations") {
      selectedDepartmentDataFilter = isJobData.filter(t => t.branch.city === locationData);
    } else if (locationData === "All Locations" && departmentData === "All Departments") {
      selectedDepartmentDataFilter = isJobData;
    } else {
      selectedDepartmentDataFilter = isJobData.filter(
        t => t.department.name === departmentData && t.branch.city === locationData
      );
    }
  }

  return (
    <Box id="hasura-open-roles" bg="#f8f9fa" pb="100px">
      <StyledCareersPage>
        <div className="containerWrapper">
          <div>
            <Box pt="100px" className="jobListHeader">
              <StyledSubTitle2 variant="neutral_900" fontWeight="font_bold">
                Open Positions
              </StyledSubTitle2>
            </Box>
            <StyledDesc2>
              <Flex
                justifyContent="space-between"
                css={css`
                  @media (max-width: 600px) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    select {
                      margin-bottom: 16px;
                      min-width: 300px;
                    }
                  }
                `}
              >
                <Box>
                  <select value={departmentData} onChange={handleDepartmentSelect}>
                    <option value="All Departments">All Departments</option>
                    {allDepartmentsArr.map(jobList => (
                      <option value={jobList?.department.name} key={jobList.id}>
                        {jobList?.department.name}
                      </option>
                    ))}
                  </select>
                  {!isRemoteJobsFilterActive && (
                    <select value={locationData} onChange={handleLocationSelect}>
                      <option value="All Locations">All Locations</option>
                      {uniqueLocations.map(jobList => (
                        <option value={jobList.branch.city} key={jobList.id}>
                          {jobList.branch.city}
                          {", "}
                          {jobList.branch.country_code === "IN"
                            ? "India"
                            : "United States of America"}
                        </option>
                      ))}
                    </select>
                  )}
                </Box>
                <Flex>
                  <Flex
                    role="button"
                    onClick={toggleRemoteJobsFilter}
                    bg="#fff"
                    borderRadius="4px"
                    border="1px solid #D5DEE5"
                    minWidth="20px"
                    minHeight="20px"
                    size="20px"
                    mr="8px"
                    css={css`
                      cursor: pointer;
                    `}
                    justifyContent="center"
                  >
                    {isRemoteJobsFilterActive ? (
                      <Image
                        src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1679295868/main-web/icons/tick_u7f1pd.svg"
                        minWidth="12px"
                        maxWidth="12px"
                        alt="right-tick"
                      />
                    ) : null}
                  </Flex>
                  <Text color="#183247" fontSize="14px" lineHeight="1.5" fontWeight={500}>
                    Remote Only
                  </Text>
                </Flex>
              </Flex>
            </StyledDesc2>
            <Box mt="80px">
              {allDepartmentsArr.map((jobsDept, index) => (
                <JobsList
                  key={index}
                  jobsListArr={selectedDepartmentDataFilter}
                  departmentName={jobsDept.department.name}
                  currentActiveDepartment={departmentData}
                />
              ))}
            </Box>
          </div>
        </div>
      </StyledCareersPage>
    </Box>
  );
};
