import React from "react";
import { css } from "styled-components";

import { Box } from "../../../globals/UIKit";

export const FormLayout = props => {
  return (
    <Box
      css={css`
        max-width: 538px;
        width: 100%;
        padding: 45px 50px;
        background: #fff;
        position: fixed;
        top: 0px;
        right: 0px;
        height: 100vh;
        z-index: 10000000 !important;
        overflow: auto;
        transition: all 0.3s ease-in-out 0s;

        box-shadow: ${props?.isJobFormActive ? "rgb(0 0 0 / 10%) -6px 0px 20px 1px" : ""};

        transform: ${props?.isJobFormActive ? "translateX(0px)" : "translateX(538px)"};

        input#portfolio-input {
          display: none;
        }

        input#cover-input {
          display: none;
        }

        input#resume-input {
          display: none;
        }

        #submit-btn {
          cursor: pointer;
        }

        #submit-btn:hover {
          background-color: #1888c7;
        }

        label {
          cursor: pointer;

          &:hover {
            box-shadow: rgb(0 0 0 / 10%) 0px 0px 26px;
          }
        }

        @media (max-width: 600px) {
          padding: 40px 30px;

          .close-btn {
            min-width: 20px;
            max-width: 20px;
          }

          h1 {
            font-size: 20px;
          }
        }
      `}
    >
      {props.children}
    </Box>
  );
};
