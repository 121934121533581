import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { StyledTeamMemberQuotes } from "./StyledCareersPage";
import { Image } from "../../globals/UIKit";
import { Emoji } from "../Emoji";
import quotesImg from "./images/green_quotes.svg";

const slideSettings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 300,
  autoplaySpeed: 10000,
  cssEase: "linear",
  dots: false,
  arrows: true,
  pauseOnHover: true,
};

const quotesArr = [
  {
    imgURL: "https://graphql-engine-cdn.hasura.io/assets/main-site/marion.jpg",
    name: "Marion",
    emoji: "swiss",
    team: "Docs & Onboarding Team",
    quote: (
      <span>
        Working for Hasura makes me truly happy because I get to work with some of the smartest and
        sweetest people I’ve ever worked with.
      </span>
    ),
  },
  {
    imgURL: "https://graphql-engine-cdn.hasura.io/assets/main-site/shraddha.jpg",
    name: "Shraddha",
    emoji: "in",
    team: "Cloud Team",
    quote: `Hasura's deeply empathetic culture is reflected in how we craft products for our customers and promote overall growth for the team.`,
  },
  {
    imgURL: "https://graphql-engine-cdn.hasura.io/assets/main-site/jesse.jpg",
    name: "Jesse Martin",
    emoji: "germany",
    team: "Marketing Team",
    quote: `Hasura is the first company I’ve worked at that is committed to remote working from the top down. As a dad with kids, this has been an incredible place to work.`,
  },
  {
    imgURL: "https://graphql-engine-cdn.hasura.io/assets/main-site/rishi.jpg",
    name: "Rishichandra Wawhal",
    emoji: "in",
    team: "Cloud Team",
    quote: (
      <span>
        We take immense pride and satisfaction in our engineering because we solve fundamental
        problems for the ecosystem that we belong to.
      </span>
    ),
  },
  {
    imgURL: "https://graphql-engine-cdn.hasura.io/assets/main-site/praveen.jpg",
    name: "Praveen",
    emoji: "in",
    team: "Marketing Team",
    quote: `Working at Hasura, you get to collaborate with the smartest humans across the globe on solid challenges with an amazing work-life balance.`,
  },
  // {
  //   imgURL: "https://graphql-engine-cdn.hasura.io/assets/main-site/nicolas.jpg",
  //   name: "Nicolas",
  //   emoji: "france",
  //   team: "Console Team",
  //   quote: `At Hasura, our values don't just exist on paper but reflect in every part of the work we do. It is a great pleasure that I am part of Hasura.`,
  // },
];

export const TeamMemberQuotes = () => (
  <StyledTeamMemberQuotes>
    <section className="containerWrapper">
      <Slider {...slideSettings}>
        {quotesArr.map(({ imgURL, quote, name, team, emoji }, index) => (
          <div className="quote_div" key={index}>
            <div className="quote-content-div">
              <Image src={quotesImg} alt="team_quotes" />
              <h2>{quote}</h2>
              <p>
                {name} <Emoji variant={emoji} minWidth="20px" ml="3px" mt="-2px" />
                <br />
                {team}
              </p>
            </div>
            <Image src={imgURL} alt={name} className="quote_dev_img" />
          </div>
        ))}
      </Slider>
    </section>
  </StyledTeamMemberQuotes>
);
