import React from "react";
import styled from "styled-components";

const StyledCareersNetworkLoader = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 130px);

  .loader {
    margin: -100px auto 100px;
    font-size: 12px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #1699e2, 1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2),
        2.5em 0em 0 0em rgba(22, 153, 226, 0.2), 1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2),
        0em 2.5em 0 0em rgba(22, 153, 226, 0.2), -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2),
        -2.6em 0em 0 0em rgba(22, 153, 226, 0.5), -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.7), 1.8em -1.8em 0 0em #1699e2,
        2.5em 0em 0 0em rgba(22, 153, 226, 0.2), 1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2),
        0em 2.5em 0 0em rgba(22, 153, 226, 0.2), -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2),
        -2.6em 0em 0 0em rgba(22, 153, 226, 0.2), -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.5),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.7), 2.5em 0em 0 0em #1699e2,
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2), 0em 2.5em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2), -2.6em 0em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.5), 2.5em 0em 0 0em rgba(22, 153, 226, 0.7),
        1.75em 1.75em 0 0em #1699e2, 0em 2.5em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2), -2.6em 0em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2), 2.5em 0em 0 0em rgba(22, 153, 226, 0.5),
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.7), 0em 2.5em 0 0em #1699e2,
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2), -2.6em 0em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2), 2.5em 0em 0 0em rgba(22, 153, 226, 0.2),
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.5), 0em 2.5em 0 0em rgba(22, 153, 226, 0.7),
        -1.8em 1.8em 0 0em #1699e2, -2.6em 0em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2), 2.5em 0em 0 0em rgba(22, 153, 226, 0.2),
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2), 0em 2.5em 0 0em rgba(22, 153, 226, 0.5),
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.7), -2.6em 0em 0 0em #1699e2,
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2), 2.5em 0em 0 0em rgba(22, 153, 226, 0.2),
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2), 0em 2.5em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.5), -2.6em 0em 0 0em rgba(22, 153, 226, 0.7),
        -1.8em -1.8em 0 0em #1699e2;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #1699e2, 1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2),
        2.5em 0em 0 0em rgba(22, 153, 226, 0.2), 1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2),
        0em 2.5em 0 0em rgba(22, 153, 226, 0.2), -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2),
        -2.6em 0em 0 0em rgba(22, 153, 226, 0.5), -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.7), 1.8em -1.8em 0 0em #1699e2,
        2.5em 0em 0 0em rgba(22, 153, 226, 0.2), 1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2),
        0em 2.5em 0 0em rgba(22, 153, 226, 0.2), -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2),
        -2.6em 0em 0 0em rgba(22, 153, 226, 0.2), -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.5),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.7), 2.5em 0em 0 0em #1699e2,
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2), 0em 2.5em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2), -2.6em 0em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.5), 2.5em 0em 0 0em rgba(22, 153, 226, 0.7),
        1.75em 1.75em 0 0em #1699e2, 0em 2.5em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2), -2.6em 0em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2), 2.5em 0em 0 0em rgba(22, 153, 226, 0.5),
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.7), 0em 2.5em 0 0em #1699e2,
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.2), -2.6em 0em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2), 2.5em 0em 0 0em rgba(22, 153, 226, 0.2),
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.5), 0em 2.5em 0 0em rgba(22, 153, 226, 0.7),
        -1.8em 1.8em 0 0em #1699e2, -2.6em 0em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2), 2.5em 0em 0 0em rgba(22, 153, 226, 0.2),
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2), 0em 2.5em 0 0em rgba(22, 153, 226, 0.5),
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.7), -2.6em 0em 0 0em #1699e2,
        -1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(22, 153, 226, 0.2),
        1.8em -1.8em 0 0em rgba(22, 153, 226, 0.2), 2.5em 0em 0 0em rgba(22, 153, 226, 0.2),
        1.75em 1.75em 0 0em rgba(22, 153, 226, 0.2), 0em 2.5em 0 0em rgba(22, 153, 226, 0.2),
        -1.8em 1.8em 0 0em rgba(22, 153, 226, 0.5), -2.6em 0em 0 0em rgba(22, 153, 226, 0.7),
        -1.8em -1.8em 0 0em #1699e2;
    }
  }
`;

export const CareersNetworkLoader = () => (
  <StyledCareersNetworkLoader>
    <div className="loader">Loading...</div>
  </StyledCareersNetworkLoader>
);
