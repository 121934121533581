import React from "react";
import { css } from "styled-components/macro";

import { Image } from "../../globals/UIKit";
import indiaEmoji from "../../images/emoji_india.svg";
import franceEmoji from "../../images/emoji_france.svg";
import germanyEmoji from "../../images/emoji_germany.svg";
import swissEmoji from "../../images/emoji_swiss.svg";

const emojiReferenceMap = {
  in: indiaEmoji,
  france: franceEmoji,
  germany: germanyEmoji,
  swiss: swissEmoji,
  default: indiaEmoji,
};

export const Emoji = props => {
  const { isInline, minWidth, variant } = props;

  const currentEmoji = emojiReferenceMap[variant] || emojiReferenceMap.default;

  return (
    <Image
      src={currentEmoji}
      alt="INDIA"
      isInline
      css={
        isInline
          ? css`
              display: inline-block !important;
            `
          : ``
      }
      minWidth={minWidth}
      {...props}
    />
  );
};

Emoji.defaultProps = {
  isInline: true,
  minWidth: "18px",
};
