import React, { useEffect, useState } from "react";

import { CareersLanding } from "./LandingBanner";
import { HasuraValues } from "./HasuraValues";
import { StyledCareersPage } from "./StyledCareersPage";
import { HasuraPerks } from "./HasuraPerks";
import { TeamSlider } from "./TeamSlider";
import { TeamMemberQuotes } from "./TeamMemberQuotes";
import { FreshDeskJobs } from "./FreshdeskJobs";
import { JobDetailsPage } from "./JobDetailsPage";
import { FRESHTEAM_API_URL } from "../../endpoints";
import { CareersNetworkLoader } from "./CareersNetworkLoader";
import "./styles.scss";

export const CareersHomePage = ({ location, searchJobId, freshteamJobsData }) => {
  const [jobsDataArr, updateJobsData] = useState(freshteamJobsData);

  const [currentPageData, updateCurrentPageData] = useState([]);

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (searchJobId) {
      const individualJobPageData = jobsDataArr.filter(
        jobObj =>
          jobObj.applicant_apply_link.toString() ===
          `https://hasura.io/careers/?jobId=${searchJobId}`
      );

      updateCurrentPageData(individualJobPageData);
    }
  }, [searchJobId]);

  useEffect(() => {
    fetch(`${FRESHTEAM_API_URL}/freshteam-jobs`)
      .then(res => res.json())
      .then(
        result => {
          // updateNetworkLoadingStatus(false);

          // Filter - Active jobs
          if (result && result.constructor.name === "Array" && result?.length) {
            const activeJobsData = result.filter(jobObj => !jobObj.deleted);

            updateJobsData(activeJobsData);
          }
        },
        error => {
          console.error(error);

          setIsError(true);
        }
      );
  }, []);

  // Indiv Job Page ******** //
  if (searchJobId && currentPageData.length > 0) {
    return (
      <StyledCareersPage>
        <JobDetailsPage jobDetailsData={currentPageData[0]} location={location} />
        <HasuraValues />
        <TeamSlider />
        <TeamMemberQuotes />
        <HasuraPerks />
      </StyledCareersPage>
    );
  }

  if (jobsDataArr) {
    return (
      <StyledCareersPage>
        <CareersLanding />
        <HasuraValues />
        <TeamSlider />
        <TeamMemberQuotes />
        <HasuraPerks />
        <FreshDeskJobs location={location} jobsDataArr={jobsDataArr} />
      </StyledCareersPage>
    );
  }

  if (isError) {
    return <p>Network Error: Something went wrong. Please try again after sometime.</p>;
  }

  return <CareersNetworkLoader />;
};
