import React from "react";

import { CareersHomePage } from ".";
import Layout from "../layout";
import Seo from "../seo";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/careers_social_share.jpg",
};

const canonicalUrl = "https://hasura.io/careers/";

const Careers = props => {
  const freshteamJobsData = props?.pageContext?.freshteamData;

  const searchParams = new URLSearchParams(props.location?.search);

  const searchJobId = searchParams.get("jobId");

  return (
    <Layout location={props.location}>
      <Seo
        title="Work with us | Hasura GraphQL Engine"
        description="Hasura gets you instant, realtime, secure & scalable GraphQL APIs. Our team helps developers & enterprises fast track product development. Work with us!"
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      <CareersHomePage
        location={props.location}
        searchJobId={searchJobId}
        freshteamJobsData={freshteamJobsData}
      />
    </Layout>
  );
};

export default Careers;
